.header-color {
  background: #43C6AC;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #191654, #43C6AC);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #191654, #43C6AC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #2C3E50;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #4CA1AF, #2C3E50);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #4CA1AF, #2C3E50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img{
  height: 250px;
  padding-top: 5em;
}

.banner-text{
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
  text-align: center;

}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

/*project page*/

.category-tabs {
  color: black;
  background-image: url('./components/img/lightbackground.jpg');
  background-size: cover;
  padding-bottom: 15em;
}
.category-tabs h1 {
  color: black;
  margin: auto;
  position: flex;
}
.projects-grid {
  display: flex;
}

.projects-grid h1 {
  font-size: 2em;
}

/*individual projects*/
.projects h1{
  font-size: 3em;
  color: #0D3255;
}
.projects h3{
  font-size: 2em;
  color: #0D3255;
}

.projects {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}
.projects-img1 {
  display:flex;

}

.projects-img {
  display:flex;
  width: 100%;
  height: 100%;
}
.projects-img img {
  width:100%;
}
.projects p {
  font-size: 1.2em;
}

/*Resume Page CSS*/
.resume {
  color: white;
  margin-left: 200px;
  position: center;
}

/*Footer*/
.footer {
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
}
.footer p {
  font-size: 1.3em;
  margin-top: 1em;
  color: white;
}
.footer h4 {
  color:white;
}
.footer-social-links i{
  font-size: 2em;
  margin-left: 15px;
  margin-right: 15px;
  color: white
}

/*About*/
.about-img {
  display:center;
  margin: auto;
}

.link-alt {
  font-family: inherit;
  background-color: rgba(0, 0, 0, 0.05);
  color: #0D3255;
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: none;
}